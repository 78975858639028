import React, { useEffect, useState } from "react";
import { BrCard, BrDateTimePicker, BrButton } from "@govbr-ds/react-components";
import Select from "../../components/Select/Select";
import {Row, Col, Input, Card, Textarea, Upload, Button, Message} from "../../components";

import {Formik} from "formik";

import {useContatoFormSchema} from "./ContatoFormSchema";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";

const ContatoForm = () => {

    const { criarContato } = useRequisicaoSite();
    const {contatoFormSchema, contatoFormInicial} = useContatoFormSchema();
    const [mensagem , setMensagem] = useState<any>({
        type: '',
        msg: ''
    })
    return (
        <Formik
            initialValues={contatoFormInicial}
            onSubmit={async (values, {setErrors}) => {
                await criarContato(values).then(res => {
                    if (res.data.sucesso) {
                        setMensagem({
                            mensagem: 'Contato enviado com sucesso!',
                            type: 'success'
                        });
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                }).catch(error => {
                    const returnError = error.response.data.errors
                    setErrors(returnError);
                });
                }
            }
            validationSchema={contatoFormSchema}
        >
            {({ values, errors, touched,
                  handleSubmit, setFieldValue, handleChange, setErrors }) =>
                (
                    <form
                        name="formCursoNovo"
                        method={'GET'}
                        onSubmit={handleSubmit}
                    >
                        <h5 className={'mb-0 pb-0'}>Contato</h5>
                        <h1 className={'titulo'}>Fale Conosco </h1>
                        <Card p={3} pt={0}>
                            {mensagem && mensagem.type &&
                                <Message hasCloseButton={false} category='message' messageTitle={mensagem.mensagem} type={mensagem.type}/>
                            }
                            <Row>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Input
                                        id="nome"
                                        placeholder="Informe seu nome..."
                                        label="Nome Completo"
                                        value={values.nome}
                                        onChange={handleChange}
                                        status={errors.nome ? 'danger' : undefined}
                                        feedbackText={errors.nome ? errors.nome : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="email"
                                        placeholder="E-mail"
                                        label="Informe seu e-mail..."
                                        value={values.email}
                                        onChange={handleChange}
                                        status={errors.email ? 'danger' : undefined}
                                        feedbackText={errors.email ? errors.email : ''}
                                    />
                                </Col>
                                <Col sm={12} md={3} lg={3} mt="3">
                                    <Input
                                        id="cpf"
                                        placeholder="Informe seu CPF..."
                                        label="CPF"
                                        value={values.cpf}
                                        onChange={handleChange}
                                        status={errors.cpf ? 'danger' : undefined}
                                        feedbackText={errors.cpf ? errors.cpf : ''}
                                    />
                                </Col>
                                <Col sm={12} md={3} lg={3} mt="3">
                                    <Input
                                        id="rg"
                                        placeholder="Informe seu RG..."
                                        label="RG"
                                        value={values.rg}
                                        onChange={handleChange}
                                        status={errors.rg ? 'danger' : undefined}
                                        feedbackText={errors.rg ? errors.rg : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="telefone"
                                        placeholder="Informe seu Telefone..."
                                        label="Telefone"
                                        value={values.telefone}
                                        onChange={handleChange}
                                        status={errors.telefone ? 'danger' : undefined}
                                        feedbackText={errors.telefone ? errors.telefone : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="cep"
                                        placeholder="Informe seu CEP..."
                                        label="CEP"
                                        value={values.cep}
                                        onChange={handleChange}
                                        status={errors.cep ? 'danger' : undefined}
                                        feedbackText={errors.cep ? errors.cep : ''}
                                    />
                                </Col>
                                <Col sm={12} md={4} lg={4} mt="3">
                                    <Input
                                        id="cidade"
                                        placeholder="Informe sua Cidade..."
                                        label="Cidade"
                                        value={values.cidade}
                                        onChange={handleChange}
                                        status={errors.cidade ? 'danger' : undefined}
                                        feedbackText={errors.cidade ? errors.cidade : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="bairro"
                                        placeholder="Informe seu Bairro..."
                                        label="Bairro"
                                        value={values.bairro}
                                        onChange={handleChange}
                                        status={errors.bairro ? 'danger' : undefined}
                                        feedbackText={errors.bairro ? errors.bairro : ''}
                                    />
                                </Col>
                                <Col sm={12} md={6} lg={6} mt="3">
                                    <Input
                                        id="endereco"
                                        placeholder="Informe seu Endereço..."
                                        label="Endereço"
                                        value={values.endereco}
                                        onChange={handleChange}
                                        status={errors.endereco ? 'danger' : undefined}
                                        feedbackText={errors.endereco ? errors.endereco : ''}
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Textarea label={'Solicitação'} maxLength={5}
                                              id='solicitacao'
                                              onChange={handleChange}
                                              status={errors.solicitacao ? 'danger' : undefined}
                                              feedbackText={errors.solicitacao ? errors.solicitacao : ''} ></Textarea>
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="3">
                                    <Upload label={'Anexos'} uploadTimeout={300}></Upload>
                                </Col>
                                <Col sm={12} md={12} lg={12} mt="5">
                                    <Button primary className={'align-left'}> Enviar</Button>
                                </Col>
                            </Row>
                        </Card>
                    </form>
                )}
        </Formik>
    )
}

export default ContatoForm;
