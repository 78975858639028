import React, {useEffect, useState} from "react";
import { Col, Container, Item, List, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import * as Entities from "html-entities";
import SecretariaView from "./SecretariaView";
import NoticiaCard from "../components/noticia-painel/NoticiaCard";
import Carousel from "../../components/Carousel/Carousel";


export default function Secretaria() {

    const navigate =  useNavigate();

    const [secretariaList, setSecretariaList] = useState<any>()
    const [secretaria, setSecretaria] = useState<any>()
    const [noticiaList, setNoticiaList] = useState<any>([])
    const { getNoticias, getSecretarias, getSlids } = useRequisicaoSite();

    const handleVerSecretaria =  (secretaria) => {
        setSecretaria(secretaria);
        navigate('/secretaria/' +  Entities.decode(secretaria.secretaria.toLowerCase() + '/' + secretaria.id), {
            state: { secretaria: secretaria }, // Pass the user ID as a prop
        });
    };

    const handleDataNoticia = async (id) => {
        await getNoticias({limite: 10, page: 1}).then(r => {
            const result = r.data.items;
            setNoticiaList(result)
        });
    };

    const [slids, setSlids] = useState<any>([])
    useEffect(() => {
        const handleDataSlids = async () => {
            await getSlids({limite: 4}).then(r => {
                const result = r.data;
                setSlids(result)
            });
        };
        handleDataSlids()
    }, []);

    useEffect(() => {
        const handleDataNoticia = async () => {
            await getNoticias({limite: 10, page: 1}).then(r => {
                const result = r.data.items;
                setNoticiaList(result)
            });
        };

        handleDataNoticia()
    }, [])
    useEffect(() => {
        const handleDataFiltro = async () => {
            await getSecretarias().then(r => {
                setSecretariaList(r.data)
            });

        };
        handleDataFiltro()
    }, [])
    return (
        <div className={'secretaria'}>
            <Container>
                <Row>
                    <Col className="col-3">
                        <List title="Secretarias">
                            {Array.isArray(secretariaList) && secretariaList?.map((linha) => (
                                <a href='#' onClick={() => handleVerSecretaria(linha)} key={'secretaria-item-nav-'+linha.id}>
                                <Item py={2} showDividerAfter >
                                    <Row>
                                        <Col auto>
                                            <i className="far fa-arrow-alt-circle-right"></i>
                                        </Col>
                                        <Col>
                                            {linha.nome}
                                        </Col>
                                    </Row>
                                </Item>
                                </a>
                            ))}
                            {!Array.isArray(secretariaList) && <p>No data available.</p>}
                        </List>
                    </Col>
                    <Col >

                        <Routes>
                            <Route path="/" element={<>
                                <h5 className={'mb-0 pb-0'}>PRINCIPAIS</h5>
                                <h1 className={'titulo'}>NOTÍCIAS </h1>
                                {/*<NoticiaCard noticiaList={noticiaList} ></NoticiaCard>*/}
                                {slids && slids.length > 0 &&
                                    <Container className={'container-hero'}>
                                        <Carousel hybrid={'vertical'} circular id={'hero'}>
                                            {slids.map((linha) => (
                                                <a href={linha.link || '#'}>
                                                    <Carousel.Page active pageTitle={linha.titulo} stepName={linha.titulo} backgroundImage={linha.slideUrl}>
                                                    </Carousel.Page>
                                                </a>
                                            ))}

                                        </Carousel>
                                    </Container>
                                }
                            </>} />
                            <Route path="/:secretaria/:id" element={<SecretariaView secretaria={secretaria} setSecretaria={setSecretaria}/>} />
                        </Routes>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}