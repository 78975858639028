import React, {useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter} from 'react-router-dom';
//Rotas
import BrFooter from "./components/FndeThema/BrFooter";

import PaginaInicial from "./pages/Home/PaginaInicial";
import Secretaria from "./pages/Secretaria/Secretaria";
import Imprensa from "./pages/Noticia/Imprensa";
import Municipio from "./pages/Municipio/Municipio";
import Prefeitos from "./pages/Municipio/Prefeitos";
import Servico from "./pages/Servico/Servico";
import Contato from "./pages/Contato/Contato";
import Buscar from "./pages/Buscar/Buscar";
import TopHeader from "./components/TopHeader";
import Noticia from "./pages/Noticia/Noticia";


export default function AppRoutes() {

    const [mensagem, setMensagem] = useState({
        type: '',
        mensagem: ''
    })
    useEffect(() => {
        // Define uma função que será chamada após 1000 milissegundos (1 segundo)
        const timerId = setTimeout(() => {
            // Atualiza o estado incrementando o contador
            setMensagem({
                type: '',
                mensagem: ''
            });
        }, 1000);

        // Retorna uma função de limpeza que cancela o timer quando o componente é desmontado ou o estado é atualizado
        return () => clearTimeout(timerId);
    }, [mensagem]); // Executa o efeito sempre que o estado `count` for alterado

    return (<>

        <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <TopHeader/>
            <main className="d-flex flex-fill" id="main">
                <div className="main-app">
                    <Routes>
                        <Route path="/" exact element={<PaginaInicial/>}/>
                        <Route path="/secretaria/*" exact element={<Secretaria/>}/>
                        <Route path="/acesso-a-informacao/*" exact element={<Imprensa/>}/>
                        <Route path="/o-municipio/*" exact element={<Municipio/>}/>
                        <Route path="/prefeitos/*" exact element={<Prefeitos/>}/>
                        <Route path="/servicos/*" exact element={<Servico/>}/>
                        <Route path="/contato/*" exact element={<Contato/>}/>
                        <Route path="/busca/:sobre" exact element={<Buscar/>}/>
                    </Routes>
                </div>
            </main>
            <BrFooter/>
        </BrowserRouter>align-items-center justify-content-center
    </>)
}
