import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Container, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import * as Entities from 'html-entities';
import Carousel from "../../components/Carousel/Carousel";
export default function NoticiaLista({noticia, setNoticia}) {

    const { getNoticias, getSlids } = useRequisicaoSite();

    const navigate =  useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [noticiaList, setNoticiaList] = useState<any>([])
    const [paginacao, setPaginacao] = useState<any>([])
    const [pageNumber, setPageNumber] = useState<number>();
    const [pageSize, setPageSize] = useState<number>();

    const handleVerNoticia = async (noticia) => {
        setNoticia(noticia);
        navigate('noticia/' + noticia.slug , {
            state: { noticia: noticia }, // Pass the user ID as a prop
        });
    };

    const handleDataFiltro = async () => {
        await getNoticias(params).then(r => {
            const result = r.data.items;
            const paginacao = r.data.pagination;
            setPaginacao(paginacao)
            setNoticiaList(result)
        });
    };

    // Ao trocar a página, recarregar os dados
    useEffect(() => {
        if (pageNumber !== undefined) {
            urlSearchParams.set('page', String(pageNumber))
            const queryString = urlSearchParams.toString();
            navigate("?" + queryString)
            handleDataFiltro()
        }
    }, [pageNumber]);

    // Ao trocar o tamanho da página
    useEffect(() => {
        if (pageSize !== undefined) {
            urlSearchParams.set('limite', String(pageSize))
            const queryString = urlSearchParams.toString();
            navigate("?" + queryString)
            handleDataFiltro()
        }
    }, [pageSize]);

    useEffect(() => {
        handleDataFiltro()
    }, []);

    const [slids, setSlids] = useState<any>([])
    useEffect(() => {
        const handleDataSlids = async () => {
            await getSlids({limite: 4}).then(r => {
                const result = r.data;
                setSlids(result)
            });
        };
        handleDataSlids()
    }, []);
    return (
        <>

            {slids && slids.length > 0 &&
                <Container className={'container-hero'}>
                    <Carousel hybrid={'vertical'} circular id={'hero'}>
                        {slids.map((linha) => (
                            <a href={linha.link || '#'}>
                                <Carousel.Page active pageTitle={linha.titulo} stepName={linha.titulo} backgroundImage={linha.slideUrl}>
                                </Carousel.Page>
                            </a>
                        ))}

                    </Carousel>
                </Container>
            }
        </>
        // <div className={'noticia-lista'}>
        //     <h5 className={'mb-0 pb-0'}>PRINCIPAIS</h5>
        //     <h1 className={'titulo'}>NOTÍCIAS </h1>
        //
        //     {noticiaList.map((linha) => (
        //         <a href={'#'} onClick={() => handleVerNoticia(linha)}>
        //             <Card mt='5' p={0} className="rounder-md"  >
        //                 <Row>
        //                     <Col sm={12} md={4} lg={4} className={'d-flex align-items-center"'}>
        //                         <img src={linha.capaUrl}
        //                              alt="..."/>
        //                     </Col>
        //                     <Col sm={12} md={8} lg={8} className={'d-flex align-items-center'}>
        //                         <div>
        //                             <h3 className="card-title mr-3"
        //                                 dangerouslySetInnerHTML={{__html: linha.titulo}}/>
        //                             <p className="card-text mr-3"
        //                                dangerouslySetInnerHTML={{__html: linha.linha_fina}}/>
        //                             <div className="text-right">
        //                                 <p className="card-text mr-3">
        //                                     <small className="text-muted">
        //                                         {linha.data}
        //                                     </small>
        //                                 </p>
        //                             </div>
        //                         </div>
        //                     </Col>
        //                 </Row>
        //             </Card>
        //         </a>
        //     ))}
        //     <Pagination
        //         type="contextual"
        //         itemCount={paginacao.total}
        //         onChange={(value) => setPageNumber(value)}
        //         onChangePageSize={(value) => setPageSize(value)}
        //     />
        // </div>
    )
}