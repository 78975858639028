import {useLocation, useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import './Ouvidoria.style.scss'
import {Card, Col, Pagination, Row} from "../../components";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import * as Entities from 'html-entities';
import Container from "../../components/Container";
import {Formik} from "formik";

import OuvidoriaForm from "./OuvidoriaForm";
import {useOuvidoriaFormSchema} from "./OuvidoriaFormSchema";

export default function Ouvidoria({ouvidoria, setOuvidoria}) {

    const { criarOuvidoria } = useRequisicaoSite();
    const {ouvidoriaFormInicial, ouvidoriaFormSchema } = useOuvidoriaFormSchema();

    const navigate =  useNavigate();
    const search = useLocation().search;
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const [noticiaList, setNoticiaList] = useState<any>([])

    const handleVerNoticia = async (noticia) => {
        setOuvidoria(noticia);
        navigate('noticia/' + noticia.id + '/' +  Entities.decode(noticia.titulo), {
            state: { noticia: noticia }, // Pass the user ID as a prop
        });
    };
    const [mensagem , setMensagem] = useState<any>({
        type: '',
        msg: ''
    })
    const cadastrar = async (values) => {
        await criarOuvidoria(values).then(res => {
            if (res.data.sucesso) {
                setMensagem({
                    mensagem: 'Ouvidoria cadastrada com sucesso! Seu protocolo é '+ res.data.protocolo + '.',
                    type: 'success'
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }).catch(error => {
            console.error('Erro na requisição:', error.response.data.error.message);
            setMensagem({
                mensagem: error.response.data.error.message,
                type: 'danger'
            });
        });
    }

    return (
        <div className={'ouvidoria'}>
            <h1 className={'titulo'}>Ouvidoria </h1>
            <p>Por meio da Ouvidoria do Município, é possível realizar solicitações para que a administração possa tomar as providências cabíveis, caso você tenha alguma sugestão ou reclamação, participe, faça com que nossa cidade cresça ainda mais.
                Ao realizar sua reclamação ou sugestão, você poderá fazer o acompanhamento da mesma através de nosso site. Para isso basta informar o número do protocolo no campo ao lado.</p>

                <Formik
                    initialValues={ouvidoriaFormInicial}
                    onSubmit={async (values) => {
                        // let errors = checkFieldErrors(values, validationSchema);
                        await cadastrar(values)
                    }}
                    validationSchema={ouvidoriaFormSchema}
                >
                    {({ values, errors, touched, handleSubmit, setFieldValue, handleChange }) =>
                        (
                            <form
                                name="formCursoNovo"
                                method={'GET'}
                                onSubmit={handleSubmit}
                            >
                                <OuvidoriaForm
                                    formik={{
                                        values: values,
                                        errors: errors,
                                        touched: touched,
                                        handleSubmit: handleSubmit,
                                        setFieldValue: setFieldValue,
                                        handleChange: handleChange
                                    }}
                                    mensagem={mensagem}
                                    listaUF={[{'DF' : 'Brasilia', 'SC' : 'Santa Catarina'}]}
                                />
                            </form>
                        )}
                </Formik>
        </div>

    )
}