import * as Yup from 'yup';

export const useOuvidoriaFormSchema = () => {
    const ouvidoriaFormInicial = {
        identificacao: '',
        tipo: '',
        nome: '',
        email: '',
        cpf: '',
        rg: '',
        telefone: '',
        cep: '',
        cidade: '',
        bairro: '',
        endereco: '',
        solicitacao: '',

    }

    const ouvidoriaFormSchema = Yup.object().shape({
        identificacao: Yup.string().required("Campo Obrigatório"),
        tipo: Yup.string().required("Campo Obrigatório"),
        nome: Yup.string().required("Campo Obrigatório"),
        email: Yup.string().required("Campo Obrigatório"),
        cpf: Yup.string().required("Campo Obrigatório"),
        rg: Yup.string().required("Campo Obrigatório"),
        telefone: Yup.string().required("Campo Obrigatório"),
        cep: Yup.string().required("Campo Obrigatório"),
        cidade: Yup.string().required("Campo Obrigatório"),
        bairro: Yup.string().required("Campo Obrigatório"),
        endereco: Yup.string().required("Campo Obrigatório"),
    })

    return {
        ouvidoriaFormInicial,
        ouvidoriaFormSchema,
    }
}