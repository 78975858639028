import {Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import Message from "../../components/Message";
import "./PaginaInicial.style.scss";
import React, { useEffect, useState } from "react";
import { mapaIcones } from "../../components/Util/Util";
import {Button, Card, Col, Container, Item, Modal, Row} from "../../components";
import {Icon} from "@govbr-ds/react-components";
import Carousel from "../../components/Carousel/Carousel";
import {useRequisicaoSite} from "../../services/Site/RequisicaoSite";
import NoticiaCard from "../components/noticia-painel/NoticiaCard";
import * as Entities from "html-entities";
import mapa from './../../images/mapa.svg';
import previdencia from '../../images/previdencia.svg'
interface Mensagem {
    type: any;
    mensagem: string;
}

export default function PaginaInicial() {

    const navigate =  useNavigate();
    const [noticiaList, setNoticiaList] = useState<any>([])
    const [noticiaListPlus, setNoticiaListPlus] = useState<any>([])
    const [slids, setSlids] = useState<any>([])
    const [popup, setPopup] = useState<any>([])
    const { getNoticias, getSlids, getPopup } = useRequisicaoSite();

    const handleVerNoticia = async (noticia) => {
        navigate('/acesso-a-informacao/noticia/' + noticia.slug, {
            state: { noticia: noticia }, // Pass the user ID as a prop
        });
    };
    useEffect(() => {
        const handleDataPopup = async () => {
            await getPopup({limite: 1}).then(r => {
                const result = r.data[0];
                if (result.titulo){
                    setPopup(result)
                }else{
                    setPopup(false)
                }
            });
        };
        handleDataPopup()
    }, []);
    useEffect(() => {
        const handleDataSlids = async () => {
            await getSlids({limite: 4}).then(r => {
                const result = r.data;
                setSlids(result)
            });
        };
        handleDataSlids()
    }, []);
    useEffect(() => {
        const handleDataFiltro = async () => {
            await getNoticias({limite: 4}).then(r => {
                const result = r.data.items;
                setNoticiaList(result)
            });
        };
        handleDataFiltro()
    }, []);
    useEffect(() => {
        const handleDataFiltro = async () => {
            await getNoticias({limite: 4, page: 2}).then(r => {
                const result = r.data.items;
                setNoticiaListPlus(result)
            });
        };
        handleDataFiltro()
    }, []);
    return (
        <div className={'pagina-inicial'}>
            {slids && slids.length > 0 &&
                <Container className={'container-hero'}>
                    <Carousel hybrid={'vertical'} circular id={'hero'}>
                        {slids.map((linha) => (
                            <a href={linha.link || '#'}>
                                <Carousel.Page active pageTitle={linha.titulo} stepName={linha.titulo} backgroundImage={linha.slideUrl}>
                                </Carousel.Page>
                            </a>
                        ))}

                    </Carousel>
                </Container>
            }
            {/*<Container className="container-news">*/}
            {/*    <Row m={0} p={0}>*/}
            {/*        {noticiaList &&*/}
            {/*            <Col sm={12} md={7} lg={7} className={'news-principal'} >*/}
            {/*                <NoticiaCard noticiaList={noticiaList} ></NoticiaCard>*/}
            {/*        </Col>*/}
            {/*        }*/}
            {/*        <Col sm={12} md={5} lg={5}>*/}
            {/*            <Row>*/}
            {/*                <Col sm={12} md={12} lg={12}>*/}
            {/*                    {noticiaListPlus.map((linha) => (*/}
            {/*                        <a href={'#'} onClick={() => handleVerNoticia(linha)} className={'noticia-lista'}>*/}
            {/*                            <Card  p={0} className="rounder-md">*/}
            {/*                                <Row>*/}
            {/*                                    <Col sm={12} md={4} lg={4} className={'d-flex align-items-center"'}>*/}
            {/*                                        <img src={linha.capaUrl}*/}
            {/*                                             alt="..."/>*/}
            {/*                                    </Col>*/}
            {/*                                    <Col sm={12} md={8} lg={8} className={'d-flex align-items-center pt-2'}>*/}
            {/*                                        <div>*/}
            {/*                                            <span className="card-title mr-3 label"*/}
            {/*                                                dangerouslySetInnerHTML={{__html: linha.titulo}}/>*/}
            {/*                                            <div className="text-right">*/}
            {/*                                                <p className="card-text mr-3">*/}
            {/*                                                    <small className="text-muted">*/}
            {/*                                                        {linha.data}*/}
            {/*                                                    </small>*/}
            {/*                                                </p>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                    </Col>*/}
            {/*                                </Row>*/}
            {/*                            </Card>*/}
            {/*                        </a>*/}
            {/*                    ))}*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Container>*/}
            <Container className={'container-servicos'}>
                <Row>
                    <Col sm={12} md={12} lg={12} className={'text-center'}>
                        <h2>Serviços</h2>
                        <p>Este é o acesso para os serviços, você pode acessar recursos disponíveis.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'http://transparencia.vilhena.ro.gov.br/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-search'} size={'3x'}/></div>
                                <h4>Portal Transparência</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5'>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-info'} size={'3x'}/></div>
                                <h4>e-SIC</h4>
                                <div className="menu">
                                    <ul>
                                        <li className={'title label'}>Acessar Serviços</li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/esic"
                                               title="e-SIC"> e-SIC </a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/sic-consulta"
                                               title="Consultar e-SIC"> Consultar e-SIC </a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="http://transparencia.vilhena.ro.gov.br/portaltransparencia/sic-fisico"
                                               title="SIC-Físico"> SIC-Físico </a></li>
                                    </ul>
                                </div>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://vilhenaro.webiss.com.br/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-credit-card'} size={'3x'}/></div>
                                <h4> WebISS - Nota Fiscal de Serviço Eletrônica</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col>
                        <Card mt='5' href={'https://vilhena.oxy.elotech.com.br/portal-servidor/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-users'} size={'3x'}/></div>
                                <h4>Portal RH</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://apps.vilhena.ro.gov.br/cemiterio'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'far fa-file'} size={'3x'}/></div>
                                <h4>Web Cemitério</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5'>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-dollar-sign'} size={'3x'}/></div>
                                <h4>Portal do Contribuinte</h4>
                                <div className="menu">
                                    <ul>
                                        <li className={'title label'}><a id="module-item-a" target="_blank" style={{color: '#ffffff'}}
                                                                         href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/inicio "
                                                                         title="Acessar Serviços">Acessar
                                            Serviços</a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/consulta-carne"
                                               title="Emissão de 2ª Via de Carnê"> Emissão de 2ª Via de Carnê </a>
                                        </li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/consulta-debitos"
                                               title="Consulta de Débitos"> Consulta de Débitos </a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/portal-contribuinte/emissao-certidoes"
                                               title="Emissão de Certidões"> Emissão de Certidões </a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/alvaraonline"
                                               title="Alvará"> Alvará </a>
                                        </li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/cidadao"
                                               title="ITBI on-line - Cidadão"> ITBI on-line - Cidadão </a></li>
                                        <li><a id="module-item-a" target="_blank"
                                               href="https://vilhena.oxy.elotech.com.br/itbi"
                                               title="ITBI on-line - Servidor Municipal"> ITBI on-line - Servidor
                                            Municipal </a></li>
                                    </ul>
                                </div>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://www1.consignet.com.br/portal/#!/vilhena'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-pager'} size={'3x'}/></div>
                                <h4>Portal Consignet</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col>
                        <Card mt='5' href={'https://vilhena.oxy.elotech.com.br/protocolo/1/consultaProcesso'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'far fa-file-alt'} size={'3x'}/></div>
                                <span className={'text-danger text-center'}>Novo sistema</span>
                                <h4 className={'mt-2'}>Controle de Processos OXY Protocolo</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://transparencia.vilhena.ro.gov.br/portaltransparencia/licitacoes'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-hammer'} size={'3x'}/></div>
                                <h4>Licitações</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    {/*<Col sm={12} md={3} lg={3}>*/}
                    {/*    <Card mt='5' href={'https://vilhena.omegaeducacional.com'}>*/}
                    {/*        <Card.Content>*/}
                    {/*            <div className={'circle-icon'}><Icon icon={'fas fa-graduation-cap'} size={'3x'}/></div>*/}
                    {/*            <h4>Sistema Educacional</h4>*/}

                    {/*        </Card.Content>*/}
                    {/*    </Card>*/}
                    {/*</Col>*/}
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://dov.vilhena.ro.gov.br/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-book'} size={'3x'}/></div>
                                <h4>DOV - Diário Oficial</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col>
                        <Card mt='5' href={'https://app.titan.email/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><Icon icon={'fas fa-at'} size={'3x'}/></div>
                                <h4>E-mails</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://www.google.com/maps/d/u/0/viewer?mid=1KHtP9XBolF3Xa0GE5hG4Ha3m8_w_DnU&ll=-12.753912235768915%2C-60.13358220898438&z=13'}>
                            <Card.Content>
                                <div className={'circle-icon'}><img src={mapa} alt=""/></div>
                                <h4>Mapa</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} lg={3}>
                        <Card mt='5' href={'https://vivaprev.com.br/'}>
                            <Card.Content>
                                <div className={'circle-icon'}><img src={previdencia} className={'ml-1'} alt=""/></div>
                                <h4>Previdencia</h4>
                            </Card.Content>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container className="container-podcast">
                <Row>
                    <Col sm={12} md={12} lg={12} className={'text-center'}>
                        <h2>Podcast</h2>
                    </Col>
                </Row>
                <iframe src="https://open.spotify.com/embed-podcast/show/7sNdl4e0j8uH9dhKPcza8Y" width="100%"
                        height="160" frameBorder="0" allowTransparency allow="encrypted-media"/>
            </Container>
            {popup &&
                <Modal
                    className={'popup'}
                    title={""}
                    useScrim={popup}
                    modalOpened={popup}
                    useModalFooter={false}>
                    <Button circle primary onClick={() => {setPopup(false)}}>X</Button>
                    {popup.titulo && popup.link &&
                        <a href={popup.link}>
                            <img src={popup.imagemUrl} className="rounder-md shadow-md" alt={popup.titulo}/>
                        </a>
                    }
                    {popup.titulo && !popup.link &&
                        <img src={popup.imagemUrl} className="rounder-md shadow-md" alt={popup.titulo}/>
                    }
                </Modal>
            }

        </div>

    );
}